@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    font-family: 'Special Elite', cursive;
}

.btn {
    height: 200px;
    width: 100%;
}

.container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 2em;
    color: white;
    text-shadow: 1px 1px 2px black;
}

.link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
